
h2 {
	text-transform: none !important;
	display: flex;
	justify-content: space-between;
}
h3 {
	font-size: 18px !important;
	margin-bottom: 10px !important;
	font-weight: bold !important;
}
.modifier {
	font-size: 15px;
	display: grid;
	grid-template-columns: 1fr max-content;
	width: 100%;
	border-bottom: solid 1px #5c5757;
	line-height: 30px;
	grid-column-gap: 10px;

	.value {
		color: #fff;
		font-size: 20px;
	}
}
